import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Container, Input } from '@material-ui/core'

import Layout from '../components/layout'
import SEO from '../components/seo'
import CourseWrapper from '../components/CourseWrapper'

import { PageTitle } from '../components/PageTitle'
import { SectionTitle, SearchBar } from '../styles/pages/presencial'

export default function SegundaGraduacaoPage(props) {
  const { data } = props
  const { edges } = data.allMarkdownRemark

  const [filteredCourses, setFilteredCourses] = useState(edges)
  const [query, setQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])

  useEffect(() => {
    const posts = filteredCourses.filter(({ node }) =>
      node.frontmatter.name.toLowerCase().includes(query.toLowerCase())
    )
    setSearchResults(posts)
  }, [query.normalize("NFC").toLowerCase()])

  return (
    <Layout>
      <SEO title="Segunda Graduação" />

      <Container style={{ marginTop: 70 }}>
        <PageTitle>Segunda Graduação</PageTitle>

        <p
          style={{
            marginTop: 50,
            fontSize: 18,
            color: '#333',
            textAlign: 'justify',
            lineHeight: '1.8em',
            wordSpacing: 8,
          }}
        >
          Oferecemos a
          <span style={{ textDecoration: 'underline', fontWeight: 'bold', marginLeft: '6px' }}>
            segunda graduação
          </span>{' '}
          por meio dos Programas de "Formação em Segunda Licenciatura” e em "Formação Pedagógica para Graduados”, ou mediante análise e aproveitamento dos estudos realizados pelos candidatos.
        </p>

        <span style={{ fontSize: 15, color: '#555' }}>
          *AVA – Ambiente Virtual de Aprendizagem da Faculdade Jardins é uma
          Plataforma de Aprendizagem on-line completa para otimizar a formação
          de nossos alunos, dispondo, inclusive, de vídeos aula, e-books,
          biblioteca virtual, periódicos virtuais, materiais de aula dos
          docentes e tutores, slides, artigos, textos, hipertextos, links,
          chats, fóruns, exercícios, avaliações, etc..
        </span>
      </Container>
      <Container disableGutters={true} style={{ marginTop: 60 }}>
        <SectionTitle>Cursos</SectionTitle>

        <SearchBar>
          <Input
            placeholder="Pesquisar curso"
            value={query}
            onChange={e => setQuery(e.target.value)}
            style={{ padding: 6, borderRadius: 5, border: '2px solid' }}
          />
        </SearchBar>

        <div>
          {searchResults.map((course) => (
            <CourseWrapper
              name={course.node.frontmatter.name}
              duration={course.node.frontmatter.duration}
              price={course.node.frontmatter.price}
              subscribe={course.node.frontmatter.subscribe}
              link={course.node.fields.slug}
              key={searchResults.indexOf(course)}
            />
          ))}
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___name], order: ASC }
      filter: { frontmatter: { category: { eq: "segunda" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            name
            price
            duration
            subscribe
          }
        }
      }
    }
  }
`
